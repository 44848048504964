<template>
  <div class="home">
    <div v-html="content"></div>
  </div>
</template>

<script>
import { agreement } from "@/api/my";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      content: ""
    };
  },
  created() {
    this.getxieyi(this.$route.query.type);
  },
  methods: {
    async getxieyi(type) {
      const res = await agreement({ type });
      if (res.data.code == 200) {
        this.content = res.data.data.content;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  padding: 30px 21px;
}
</style>
