import { axios } from "./../plugins/axios";
/* 获取用户资料 */
export function myInfo(data) {
  return axios.request({
    method: "POST",
    url: "/api/User/myInfo",
    params: {
      ...data
    }
  });
}
/* 我要脱单 */
export function takeOffSingle(data) {
  return axios.request({
    method: "POST",
    url: "/api/User/takeOffSingle",
    params: {
      ...data
    }
  });
}
/* 获取园区列表 */
export function getGarden(data) {
  return axios.request({
    method: "POST",
    url: "/api/Publics/getGarden",
    params: {
      ...data
    }
  });
}
/* 个人中心里面查询是否有红娘 */
export function my_matchmaker(data) {
  return axios.request({
    method: "POST",
    url: "/api/Matchmaker/my_matchmaker",
    params: {
      ...data
    }
  });
}
/*红娘列表 */
export function matchmaker_list(data) {
  return axios.request({
    method: "POST",
    url: "/api/Matchmaker/matchmaker_list",
    params: {
      ...data
    }
  });
}
/*绑定红娘 */
export function bind_matchmaker(data) {
  return axios.request({
    method: "POST",
    url: "/api/Matchmaker/bind_matchmaker",
    params: {
      ...data
    }
  });
}
/*获取协议 */
export function agreement(data) {
  return axios.request({
    method: "POST",
    url: "/api/System/agreement",
    params: {
      ...data
    }
  });
}